<template>
  <v-navigation-drawer v-model="Sidebar_drawer" :dark="SidebarColor !== 'white'" :color="SidebarColor"
    mobile-break-point="960" clipped :right="$vuetify.rtl" mini-variant-width="70" :expand-on-hover="expandOnHover" app
    id="main-sidebar">
    <v-list dense nav>

      <v-list-item-group>
      
      <v-list-item v-for="item in filteredAdminItems" :key="item.title" :to="item.to"  :active-class="` white--text`" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">{{ item.title }}</v-list-item-title>
        </v-list-item-content>

        
      </v-list-item>

      <v-list-item 
        link 
        @click="changePassword()" 
        class="no-active-effect"
      >
        <v-list-item-icon>
          <v-icon>mdi-lock-reset</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">Change Password</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item 
        link 
        @click="Logoutdial()" 
        class="no-active-effect"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
<!-- 
      <v-list-item :active-class="` white--text`" link>
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title style="font-family: poppinsmedium;">Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->

    </v-list-item-group>

    <v-dialog
      v-model="changePasswordDialog"
      persistent
      :width="$vuetify.breakpoint.name == 'xs' ? '90%' : $vuetify.breakpoint.name == 'sm' ? '50%' : $vuetify.breakpoint.name == 'md' ? '40%' : $vuetify.breakpoint.name == 'lg' ? '25%' : '25%'"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span style="font-family: Montserrat-Regular; font-size: 24px; font-weight: 700;">Change Password</span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon @click="changePasswordDialogClose()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">Current Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="oldPassword" hide-details="auto"
                  :type="showCurrentPassword ? 'text' : 'password'"
                  :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showCurrentPassword = !showCurrentPassword"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">New Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="newPassword" hide-details="auto"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">Confirm New Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="confirmNewPassword" hide-details="auto"
                  :type="showConfirmNewPassword ? 'text' : 'password'"
                  :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmNewPassword = !showConfirmNewPassword"
            :error="passwordError !== ''"
            :error-messages="passwordError"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-6>
                <v-btn block style="background-color: #00A08E;" @click="submitPasswordChange">
                  <span style="color: white;">Change Password</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="logoutdialog"
      persistent
      :width="$vuetify.breakpoint.name == 'xs' ? '90%' : $vuetify.breakpoint.name == 'sm' ? '50%' : $vuetify.breakpoint.name == 'md' ? '40%' : $vuetify.breakpoint.name == 'lg' ? '25%' : '25%'"
     
    >
    <v-card >
                        <v-layout wrap justify-center pa-5>
                            
                            <v-flex xs12 sm12 md12 lg12 xl12 pt-3 class="text-center">
                                <span>Are you sure ,You want to Logout? </span>
                            </v-flex>

                            <v-flex xs6 sm6 md6 lg6 xl6 class="text-center" pt-3>
                              <v-btn @click="LogoutdialClose()"><span>No</span></v-btn>
                            </v-flex>
                            <v-flex xs6 sm6 md6 lg6 xl6 class="text-center" pt-3>
                               <v-btn style="background-color: #00A08E;"><span style="color: white;" @click="appLogout()">Yes</span></v-btn>
                            </v-flex>
                            
                            
                        </v-layout>
                    </v-card>
   
    </v-dialog>
      
    </v-list>
  </v-navigation-drawer>
  
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Sidebar",
  // props: {
  //   expandOnHover: {
  //     type: Boolean,
  //     default: false,
  //   },
  // },
  data: () => ({
    userRole: localStorage.getItem("role"),
    adminitems: [
      {
        title: "Dashboard",
        icon: "mdi-view-dashboard",
        to: "/dashboard",
      },
      {
        title: "User List",
        icon: "mdi-account -circle",
        to: "/userList",
      },
      {
        title: "Region",
        icon: "mdi-select-marker",
        to: "/banner"
      },
      {
        title: "Country",
        icon: "mdi-map-marker",
        to: "/Country",
      },
      {
        title: "Subscription",
        icon: "mdi-cash-multiple",
        to: "/subcategory",
      },
      {
        title: "Voucher",
        icon: "mdi-ticket-percent",
        to: "/voucher",
      },
      {
        title: "Contact Us",
        icon: "mdi-mail",
        to: "/Messages",
      },
      {
        title: "Terms and Conditions",
        icon: "mdi-playlist-check",
        to: "/terms-and-conditions",
      },
      {
        title: "Refund Policy",
        icon: "mdi-message-text-lock",
        to: "/refund-policy",
      },{
        title: "Settings",
        icon: "mdi-cog",
        to: "/settings",
      },
      // {
      //   title: "Change Password",
      //   icon: "mdi-cog",
      //   to: "/Messages",
      //   xsOnly: true, 
      // },{
      //   title: "Logout",
      //   icon: "mdi-logout",
      //   to: "/Messages",
      //   xsOnly: true, 
      // },
     
    ],
    
    Home: [
      { title: "Banner", to: "/banner" },
    ],

    changePasswordDialog: false,

    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
   
    submit: false,
    value: false,
    passwordError:"",
    logoutdialog: false,
    expandOnHover: false,

  }),
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    Sidebar_drawer: {
      get() {
        return this.$store.state.Sidebar_drawer;
      },
      set(val) {
        this.$store.commit("SET_SIDEBAR_DRAWER", val);
      },
    },
    filteredAdminItems() {
      const isXsScreen = this.$vuetify.breakpoint.xsOnly;
      return this.adminitems.filter(item => !item.xsOnly || (item.xsOnly && isXsScreen));
    },
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },

  methods: {
    changePassword() {
      this.Sidebar_drawer = false;
      this.changePasswordDialog = true;
    },

    changePasswordDialogClose(){
      this.Sidebar_drawer = true;
      this.changePasswordDialog = false;
    },

    Logoutdial() {
      this.Sidebar_drawer = false;
      this.logoutdialog = true;
    },

    LogoutdialClose() {
      this.Sidebar_drawer = true;
      this.logoutdialog = false;
    },

    appLogout() {
      this.$store.commit("logoutUser", true);
    },

    submitPasswordChange() {
      this.submit = true;
      
      if (this.newPassword !== this.confirmNewPassword) {
        this.passwordError = "New Password and Confirm New Password do not match.";
      } else {
      this.passwordError = "";
        axios({
          method: "POST",
          url: "/user/change/password",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.changePasswordDialog = false;
              this.resetPasswordFields();
            }
            else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
    },
  },
};
</script>
<style lang="scss">
.custom-active-class {
  background-color: #00A08E;
  color: white;
}
.v-list-item--active {
  background-color: #00A08E;
}
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);

  .v-navigation-drawer__border {
    display: none;
  }

  .v-list {
    padding: 8px 15px;
  }

  .v-list-item {

    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }

  .success {
    background-color: #1e88e5 !important;
    border-color: #1e88e5 !important;
  }
}
.no-active-effect {
  background-color: transparent !important; /* Remove background color */
  &:hover {
    background-color: transparent !important; /* Remove hover effect */
  }
}
</style>
