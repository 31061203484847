<template>
  <div>
    <v-app-bar app clipped-left clipped-right color="white" dark>

      <v-app-bar-nav-icon
        style="color: #00A08E"
        @click="
          $vuetify.breakpoint.mdAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
        class="hidden-lg-and-up"
      ></v-app-bar-nav-icon>
      <!-- <v-app-bar-nav-icon
        style="color: #3CB043"
        class="d-block d-md-none"
        @click="
          $vuetify.breakpoint.smAndDown
            ? setSidebarDrawer(!Sidebar_drawer)
            : $emit('input', !value)
        "
      /> -->

      <!-- <v-toolbar-title v-if="$vuetify.breakpoint.smAndDown" class="align-center d-flex">
        <span class="logo-icon">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img
                src="../../assets/images/favlogo.png"
                width="200px"
                contain
                height="50px"
                class="mt-2"
              />
            </v-flex>
          </v-layout>
        </span>
      </v-toolbar-title> -->

      <v-toolbar-title class="align-center d-flex pl-0">
        <span class="logo-icon">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <v-img
                src="../../assets/images/teamitelogo.webp"
                width="200px"
                contain
                height="50px"
                class="mt-2"
              />
            </v-flex>
          </v-layout>
        </span>
      </v-toolbar-title>

      

      <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>


      <v-layout wrap justify-center>
        <v-flex sm5 md6 lg9 pl-7 class="text-center" align-self-center>
          <!-- <span style="font-family: poppinsregular; padding-top: 8px; color: black; font-size: 20px; font-weight: 600;">
            MANAGEMENT PORTAL
          </span> -->
        </v-flex>

        <v-flex sm7 md6 lg2 class="text-end">
          <v-toolbar-items class="hidden-xs-only">
            <v-layout wrap justify-center>
              <v-flex sm7 md8 lg9 pl-md-10 pl-lg-0 align-self-center>
                <v-btn small color="#00A08E" @click="changePassword()"><span style="font-family: Montserrat-Regular; font-weight: 600; color: white">Change Password</span></v-btn>
              </v-flex>
              <v-flex sm5 md4 lg3 pl-lg-5 align-self-center>
                <v-menu
                  :close-on-content-click="true"
                  :nudge-bottom="40"
                  :nudge-right="50"
                  transition="slide-y-transition"
                  bottom
                  dark
                >
                  <template v-slot:activator="{ on }">
                    <v-btn small color="#00A08E" v-on="on" style="cursor: pointer; border-radius: 5px;">
                      <v-icon>mdi-account</v-icon>
                    </v-btn>
                  </template>
                  <v-card max-width="800">
                    <v-layout pa-3>
                      <v-flex>
                        <v-layout @click="appLogout" style="cursor: pointer;">
                          <v-flex text-left>
                            <span style="font-size: 12px;">Sign Out</span>
                          </v-flex>
                          <v-flex text-right>
                            <v-icon style="font-size: 14px;">mdi-logout</v-icon>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-toolbar-items>
        </v-flex>
      </v-layout>
    </v-app-bar>

    <v-dialog
      v-model="changePasswordDialog"
      :width="$vuetify.breakpoint.name == 'xs' ? '90%' : $vuetify.breakpoint.name == 'sm' ? '50%' : $vuetify.breakpoint.name == 'md' ? '40%' : $vuetify.breakpoint.name == 'lg' ? '25%' : '25%'"
      content-class="dialog-custom"
    >
      <v-card>
        <v-layout wrap justify-center pa-8 pt-6>
          <v-flex xs12 sm12 md12 lg12>
            <v-layout wrap justify-center>
              <v-flex xs11 sm11 md11 lg11 pb-3>
                <span style="font-family: Montserrat-Regular; font-size: 24px; font-weight: 700;">Change Password</span>
              </v-flex>
              <v-flex xs1 sm1 md1 lg1>
                <v-btn icon @click="changePasswordDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-2>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">Current Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="oldPassword" hide-details="auto"
                  :type="showCurrentPassword ? 'text' : 'password'"
                  :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showCurrentPassword = !showCurrentPassword"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">New Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="newPassword" hide-details="auto"
                  :type="showNewPassword ? 'text' : 'password'"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showNewPassword = !showNewPassword"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-1>
                <span style="font-family: Lato-Regular; font-size: 16px; font-weight: 500;">Confirm New Password</span>
              </v-flex>
              <v-flex xs12 sm12 md12 lg12>
                <v-text-field outlined dense v-model="confirmNewPassword" hide-details="auto"
                  :type="showConfirmNewPassword ? 'text' : 'password'"
                  :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="showConfirmNewPassword = !showConfirmNewPassword"
            :error="passwordError !== ''"
            :error-messages="passwordError"
                ></v-text-field>
              </v-flex>

              <v-flex xs12 sm12 md12 lg12 pt-6>
                <v-btn block style="background-color: #00A08E;" @click="submitPasswordChange">
                  <span style="color: white;">Change Password</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Header",
  data: () => ({
    userData: null,
    oldPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmNewPassword: false,
    changePasswordDialog: false,
    submit: false,
    value: false,
    passwordError:"",
    showsnackbar: false,
    msg: "",
  }),
  computed: {
    ...mapState(["Sidebar_drawer"]),
  },
  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    getProfile() {
      // Fetch user profile here if needed
    },
    changePassword() {
      this.changePasswordDialog = true;
    },
    submitPasswordChange() {
      this.submit = true;
      
      if (this.newPassword !== this.confirmNewPassword) {
        this.passwordError = "New Password and Confirm New Password do not match.";
      } else {
      this.passwordError = "";
        axios({
          method: "POST",
          url: "/user/change/password",
          headers: {
            token: localStorage.getItem("token"),
          },
          data: {
            oldPassword: this.oldPassword,
            newPassword: this.newPassword,
          },
        })
          .then((response) => {
            if (response.data.status) {
              this.changePasswordDialog = false;
              this.resetPasswordFields();
            }
            else {
              this.msg = response.data.msg;
              this.showsnackbar = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    }
    },
    resetPasswordFields() {
      this.oldPassword = "";
      this.newPassword = "";
      this.confirmNewPassword = "";
      this.showCurrentPassword = false;
      this.showNewPassword = false;
      this.showConfirmNewPassword = false;
      this.submit = false;
    },
    appLogout() {
      this.$store.commit("logoutUser", true);
    },
  },
};
</script>

<style>
.router-link {
  text-decoration: none;
}
.text-color {
  background-image: linear-gradient(to right, #fa709a 0%, #fee140 100%);
}
.size {
  z-index: 1;
  position: absolute;
  border: 2px solid black;
  left: 0;
  top: 0;
}
</style>
